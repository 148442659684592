import { COUNTRY_OPTIONS } from "@lango/common/constants/countries";

export const address_fields = [
  {
    control: "select",
    name: "country",
    label: "Country",
    placeholder: "Select country",
    containerClasses: "md:col-start-1",
    options: COUNTRY_OPTIONS,
  },
  {
    control: "input",
    name: "address1",
    label: "Address",
    placeholder: "Enter address",
    containerClasses: "md:col-start-1",
  },
  {
    control: "input",
    name: "address2",
    label: "Apartment, suite, building, floor",
    placeholder: "Enter apartment, suite, building, floor, etc",
  },
  {
    control: "input",
    name: "city",
    label: "City",
    placeholder: "Enter city",
  },
  {
    control: "input",
    name: "postalCode",
    label: "Postal code",
    placeholder: "Enter postal code",
  },
];
