import { isValidPhoneNumber } from "react-phone-number-input";
import * as yup from "yup";

const maxAddressLength = 255;

export const validateAddress = yup
  .string()
  .max(
    maxAddressLength,
    `Address should not exceed ${maxAddressLength} characters`,
  )
  .required("Address is required");

export const validateAddressDetails = yup
  .string()
  .nullable()
  .notRequired()
  .max(
    maxAddressLength,
    `Address details should not exceed ${maxAddressLength} characters`,
  );

export const validateState = yup
  .string()
  .required("State is required")
  .max(25, "State can be a maximum of 25 characters");

export const validateCountry = yup
  .object()
  .shape({
    label: yup.string(),
    value: yup.string(),
  })
  .nullable()
  .required("Country is required");

export const maxPhoneNumberLength = 15;
export const validatePhoneNumber = yup
  .string()
  .required("Phone number is required")
  .max(maxPhoneNumberLength, "Phone can be a maximum of 15 characters")
  .test(
    "isValidPhoneNumber",
    "Phone number is not valid",
    (value) => value && isValidPhoneNumber(value),
  );
