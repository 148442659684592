import classNames from "@lango/common/classnames";

export const selectClasses = (props) => {
  const { rounded, whiteBackground, border, isDisabled, extraClasses, meta } =
    props;

  return classNames(
    "block w-full text-black placeholder-gray-400 focus:ring-gray-700 focus:border-gray-700",
    extraClasses,
    {
      rounded: rounded === "rounded",
      "rounded-3xl": rounded === "rounded-full",
      "bg-white": whiteBackground,
      "bg-gray-100": !whiteBackground,
      "border border-gray-200 ": border,
      "border-0": !border,
      "bg-gray-200 text-gray-600": isDisabled && rounded && !whiteBackground,
      "text-gray-600": isDisabled && !rounded,
    },
    {
      "border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500":
        meta?.touched && meta?.error,
    },
  );
};

// custom styles for react-select
export const selectStyles = (props) => {
  const { useRing, useUnderline } = props;

  return {
    control: (provided) => ({
      ...provided,
      border: "0",
      background: "none",
      boxShadow: "none",
      fontSize: "16px",
      padding: "0",
      minHeight: "none",
      lineHeight: "1.5rem",
    }),
    container: (provided, state) => ({
      ...provided,
      boxShadow:
        state?.isFocused && useRing
          ? "var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) #374151;"
          : "none",
      borderBottom: state?.isFocused && useUnderline ? "solid 2px #374151" : "", // todo: use tailwind
      paddingLeft: props?.isMulti && "8px",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      background: "black",
      color: "white",
      borderRadius: "10px 0 0 10px",
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      background: "black",
      color: "white",
      borderRadius: "0 10px 10px 0",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#9CA3AF",
      padding: "0.75rem 0.5rem 0.75rem 1.25rem",
    }),
    input: (provided, state) => ({
      ...provided,
      "& input": {
        display: "block !important",
        width: "100% !important",
        "--tw-ring-color": "none !important",
      },
      padding: "0.75rem 0.5rem 0.75rem 1.25rem !important",
      margin: "0",
    }),
    option: (provided, { data, isDisabled, isSelected, isFocused }) => {
      return {
        ...provided,
        backgroundColor: isDisabled
          ? null
          : isSelected
            ? "#000000"
            : isFocused
              ? "#E9E9E9"
              : null,
        ":active": {
          ...provided[":active"],
          backgroundColor: !isDisabled && (isSelected ? "#000000" : "#FFFFFF"),
        },
      };
    },
    indicatorsContainer: (provided) => ({
      ...provided,
      padding: "0",
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "0",
      border: "0",
      width: "100%",
    }),
    dropdownIndicator: (provided, { isDisabled }) => ({
      ...provided,
      padding: "0.75rem 1.25rem",
      color: isDisabled ? "#E9E9E9" : "black",
      display: props?.addOnText && "none",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      padding: "0",
      color: "black",
      display: "none",
    }),
    singleValue: (provided, { isDisabled }) => ({
      ...provided,
      color: !isDisabled ? "#000000" : "rgba(75, 85, 99, 1)",
      padding: "0.75rem 0.5rem 0.75rem 1.25rem !important",
      margin: 0,
    }),
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
  };
};
