/**
 * This file contains the definition of the Fields component, which renders the form fields for the billing contact section in the onboarding step 2.
 */

import { Divider } from "@aws-amplify/ui-react";
import { Box } from "@lango/common/components";
import { company_address_fields, renderFieldGroup } from "../step1/fields";
import TaxForm from "./taxForm";

/**
 * An array of objects representing the form fields for the billing contact section.
 * Each object contains the control type, name, label, and placeholder for the field.
 * @type {Array}
 */
export const billing_contact_fields = [
  {
    control: "input",
    name: "firstName",
    label: "First name",
    placeholder: "Enter first name",
  },
  {
    control: "input",
    name: "lastName",
    label: "Last name",
    placeholder: "Enter last name",
  },
  {
    control: "input",
    type: "email",
    name: "email",
    label: "Email",
    placeholder: "Enter email",
  },
  {
    control: "phone",
    name: "phone",
    label: "Phone number",
    placeholder: "Enter phone number",
  },
  {
    control: "input",
    name: "title",
    label: "Job Title (optional)",
    placeholder: "Enter job title",
  },
  {
    control: "input",
    type: "tel",
    name: "faxNumber",
    label: "Fax Number (optional)",
    placeholder: "Enter fax number",
  },
];

/**
 * The Fields component renders the form fields for the billing contact section.
 * It includes the billing contact field group, a divider, and the TaxForm component.
 * @returns {JSX.Element} The rendered Fields component.
 */
export default function Fields({ fileName }) {
  return (
    <Box extraClasses="bg-white rounded-xl shadow-md my-8">
      {renderFieldGroup("Billing Contact", [
        ...billing_contact_fields,
        ...company_address_fields,
      ])}
      <Divider />
      <TaxForm fileName={fileName} />
    </Box>
  );
}
