import { Form, useFormikContext } from "formik";
import { useModal } from "@lango/common/context";
import {
  ENABLE_AUTO_DELETE_DOCUMENT_INFO_TEXT,
  ENABLE_GLOSSARY_SUPPORT_INFO_TEXT,
  ENABLE_JOB_WORD_COUNT_LIMIT_INFO_TEXT,
} from "@lango/common/constants";
import Flex from "../../tables/components/Flex";
import FormControl from "../components/FormControl";

const ClientMTettingsFields = ({
  isAutoDeleteDocuments,
  isGlossarySupport,
  isJobWordCountLimit,
}) => {
  const { isSubmitting, dirty, values } = useFormikContext();
  const { hideModal } = useModal();
  return (
    <Form className="space-y-6">
      <FormControl
        control="toggle"
        label="Allow Custom Glossaries"
        name="glossarySupport"
        disabled={!isGlossarySupport || isSubmitting}
        infotext={!isGlossarySupport ? ENABLE_GLOSSARY_SUPPORT_INFO_TEXT : null}
      />

      <FormControl
        control="toggle"
        label="Allow Limiting the Word Count Per-Job"
        name="jobWordCountLimitEnabled"
        disabled={!isJobWordCountLimit || isSubmitting}
        infotext={
          !isJobWordCountLimit ? ENABLE_JOB_WORD_COUNT_LIMIT_INFO_TEXT : null
        }
      />

      <FormControl
        control="toggle"
        label="Delete files after delivery."
        name="automaticallyDeleteDocuments"
        disabled={!isAutoDeleteDocuments || isSubmitting}
        infotext={
          !isAutoDeleteDocuments ? ENABLE_AUTO_DELETE_DOCUMENT_INFO_TEXT : null
        }
      />

      <Flex justify="center" extraClasses="gap-4">
        <FormControl
          control="button"
          type="button"
          text="Cancel"
          importance="secondary"
          onClick={hideModal}
          disabled={isSubmitting}
        />
        <FormControl
          control="button"
          type="submit"
          importance="primary"
          text="Save"
          disabled={isSubmitting || !dirty}
          showLoader={isSubmitting}
        />
      </Flex>
    </Form>
  );
};
export default ClientMTettingsFields;
