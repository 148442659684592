import { Popover, PopoverPanel, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";

import { useReactiveVar } from "@apollo/client";
import { activeOrgVar, userIDVar } from "@lango/common/apollo";
import cn from "@lango/common/classnames";
import { DropdownTriangle } from "@lango/common/features";
import { useGetOrgNotifications } from "@lango/common/hooks";
import { useGetUserNotifications } from "@lango/common/hooks/getUserNotifications";
import { CHANNEL, EVENT, useChannelEvent } from "@lango/common/pusher";

import { PanelFooter } from "./footer";
import { NotificationHeader } from "./header";
import { NotificationList } from "./list";

/**
 * NotificationPanel Component
 *
 * Renders a notification bell icon that indicates the presence of unread notifications.
 * When clicked, it displays the NotificationPanel component that lists all notifications.
 * The component also shows a red dot on the bell icon if there are any notifications.
 * It subscribes to organization and user notification channels and refetches the notifications
 * when a new notification event is received.
 *
 * @param {Object} props - The props for the OrganizationNotifications component.
 * @param {string} [props.extraClasses] - Additional classes to apply to the component.
 * @returns {React.ReactNode} The rendered OrganizationNotifications component.
 */
const NotificationPanel = ({ extraClasses }) => {
  const navigate = useNavigate();
  const userID = useReactiveVar(userIDVar);
  const { id: organizationID } = useReactiveVar(activeOrgVar);
  const { organizationNotifications, unreadOrgCount, refetch } = useGetOrgNotifications();
  const { userNotifications, unreadUserCount, userRefetch } = useGetUserNotifications(0, 5);
  const hasUnreadNotifications = unreadOrgCount > 0 || unreadUserCount > 0;

  useChannelEvent(
    CHANNEL.ORG_NOTIFICATION_PREFIX + organizationID,
    EVENT.NEW_NOTIFICATION,
    () => refetch(),
  );

  useChannelEvent(
    CHANNEL.USER_NOTIFICATION_PREFIX + userID,
    EVENT.NEW_NOTIFICATION,
    () => userRefetch(),
  );

  return (
    <Popover className={cn(`relative ${extraClasses}`)}>
      <NotificationHeader hasUnreadNotifications={hasUnreadNotifications} />
      <PanelTransition>
        <PopoverPanel className="absolute left-6 w-screen max-w-xs -translate-x-72 transform sm:px-0 lg:max-w-xl">
          {({ close }) => (
            <>
              <DropdownTriangle positionClasses="justify-center pr-4" />
              <div className="overflow-hidden rounded-2xl shadow-lg ring-1 ring-black/5">
                <div className="relative flex flex-col bg-white h-[536px]">
                  <NotificationList
                    organizationNotifications={organizationNotifications}
                    userNotifications={userNotifications}
                    closePanel={close}
                  />
                  <PanelFooter
                    onClick={() => {
                      navigate("/notifications");
                      close();
                    }}
                  />
                </div>
              </div>
            </>
          )}
        </PopoverPanel>
      </PanelTransition>
    </Popover>
  );
};

export { NotificationPanel };

/**
 * PanelTransition Component
 *
 * Provides transition effects for the NotificationPanel component. This component
 * wraps its children with transitions that animate the appearance and disappearance
 * of the notification panel. It enhances the user experience by adding smooth
 * transitions when the panel is shown or hidden.
 *
 * @param {Object} props - The props for the PanelTransition component.
 * @param {React.ReactNode} props.children - The child components to which the transition effects will be applied.
 * @returns {React.ReactNode} The rendered transition component with its children.
 */
const PanelTransition = ({ children }) => {
  return (
    <Transition
      enter="transition ease-out duration-75"
      enterFrom="opacity-0 -translate-y-2"
      enterTo="opacity-100 translate-y-0"
      leave="transition ease-in duration-50"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 -translate-y-2"
    >
      {children}
    </Transition>
  );
};
