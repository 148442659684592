import { BUYER_APP } from "@lango/common/constants";
import env from "@lango/common/env";
import { BasePageHeading, Button } from "@lango/common/features";
import { PaginatedOrgNotifications } from "@lango/common/features/notifications/list/OrgNotifications";
import UserNotifications from "@lango/common/features/notifications/list/UserNotifications";
import Tab from "@lango/common/features/tab";
import {
  useGetUserNotifications,
  useMarkNotificationsAsRead,
  usePagination,
} from "@lango/common/hooks";
import { useLocation } from "react-router-dom";

const { appName } = env;

const NotificationsList = () => {
  const location = useLocation();
  const { pagination: { offset, pageLimit }, paginationProps } = usePagination();
  const { loading, userNotifications, totalRecords, error } = useGetUserNotifications(offset, pageLimit);
  const { handleMarkNotificationsAsRead } = useMarkNotificationsAsRead();

  const hasOrgNotifications = appName !== BUYER_APP;
  return (
    <div>
      <BasePageHeading heading="Notifications" extraClasses="mb-6" />
      <div>
        <Tab
          actions={
            location.pathname === "/notifications" && (
              <UserTabAction
                disabled={!userNotifications.some((noti) => !noti?.isRead)}
                onClick={handleMarkNotificationsAsRead}
              />
            )
          }
        >
          <Tab.Panel name="User">
            <UserNotifications
              notifications={userNotifications}
              loading={loading}
              error={error}
              paginationProps={paginationProps}
              totalRecords={totalRecords}
            />
          </Tab.Panel>
          {hasOrgNotifications && (
            <Tab.Panel name="Organization">
              <PaginatedOrgNotifications />
            </Tab.Panel>
          )}
        </Tab>
      </div>
    </div>
  );
};

const UserTabAction = (props) => (
  <Button type="button" extraClasses="p-2 text-sm" {...props}>
    Mark all as read
  </Button>
);

export { NotificationsList };
