import * as yup from "yup";
import {
  validateAddress,
  validateAddressDetails,
  validateCountry,
  validatePhoneNumber,
} from "./common";

export const validateClientContact = yup.object().shape({
  name: yup
    .string()
    .required("Contact name is required")
    .max(50, "Contact name can be a maximum of 50 characters"),
  firstName: yup
    .string()
    .required("First name is required")
    .max(50, "First name can be a maximum of 50 characters"),
  lastName: yup
    .string()
    .required("Last name is required")
    .max(50, "Last name can be a maximum of 50 characters"),
  email: yup
    .string()
    .email()
    .required("Email is required")
    .max(255, "Email can be a maximum of 255 characters"),
  phone: validatePhoneNumber,
  faxNumber: yup
    .string()
    .optional()
    .max(50, "Fax number can be a maximum of 50 characters"),
  website: yup
    .string()
    .url()
    .optional()
    .max(255, "Website can be a maximum of 255 characters"),
  country: validateCountry,
  address1: validateAddress,
  address2: validateAddressDetails,
  city: yup
    .string()
    .required("City is required")
    .max(25, "City can be a maximum of 25 characters"),
  postalCode: yup
    .string()
    .required("Postal code is required")
    .max(10, "Postal code can be a maximum of 10 characters"),
});
