import { useQuery } from "@apollo/client";

import { toastError } from "@lango/common/features/alerts/functions/toast";

import { CLIENT_PROFILE_VRI_SETTINGS } from "@lango/common/queries";
import { useParams } from "react-router-dom";
import { useFetchClientProfileID } from "./fetchClientProfileID";
import { isBuyerApp } from "../env";

export const useFetchClientProfileSettings = () => {
  const { clientProfileID: paramsID } = useParams();
  const { clientID: fetchedClientProfileID } = isBuyerApp
    ? useFetchClientProfileID()
    : { clientID: null };

  const clientProfileID = paramsID || fetchedClientProfileID;

  const { loading, error, data } = useQuery(CLIENT_PROFILE_VRI_SETTINGS, {
    variables: { clientProfileID },
    fetchPolicy: "no-cache",
    onError: (error) => toastError(error?.message),
    skip: !clientProfileID,
  });

  const initialValues = {
    ...data?.clientProfileVRISettings,
  };

  return {
    settings: initialValues,
    error,
    loading,
    isScreenShareFeatureEnabled:
      data?.clientProfileVRISettings?.isScreenShareFeatureEnabled || false,
    isVirtualBackgroundFeatureEnabled:
      data?.clientProfileVRISettings?.isVirtualBackgroundFeatureEnabled ||
      false,
    isJobWordCountLimitFeatureEnabled:
      data?.clientProfileVRISettings?.isJobWordCountLimitFeatureEnabled ||
      false,
    isGlossarySupportFeatureEnabled:
      data?.clientProfileVRISettings?.isGlossarySupportFeatureEnabled || false,
    isAutomaticallyDeleteDocumentsFeatureEnabled:
      data?.clientProfileVRISettings
        ?.isAutomaticallyDeleteDocumentsFeatureEnabled || false,
  };
};
