import { useLazyQuery, useQuery, useReactiveVar } from "@apollo/client";
import { useCallback, useEffect } from "react";

import { activeOrgVar } from "@lango/common/apollo/localState";
import { ORGANIZATION_NOTIFICATION } from "@lango/common/queries";
import { preparePaginatedOrgNotificationsRequest } from "@lango/common/request";

export const useGetPaginatedOrgNotifications = () => {
  const { id: organizationID } = useReactiveVar(activeOrgVar);
  const [paginatedNotifications, { data, loading, error }] = useLazyQuery(
    ORGANIZATION_NOTIFICATION.PAGINATED, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "cache-and-network",
    });

  const fetchOrganizationNotifications = useCallback(
    (props) => {
      paginatedNotifications(
        preparePaginatedOrgNotificationsRequest({ ...props, organizationID }),
      );
    },
    [paginatedNotifications, organizationID],
  );

  const notifications = data?.getPaginatedOrgNotifications;

  return {
    unreadCounts: notifications?.organizationNotifications.filter(
      (notification) => !notification.isRead,
    ).length,
    data: notifications?.organizationNotifications || [],
    totalRecords: notifications?.totalRecords,
    fetchData: fetchOrganizationNotifications,
    loading,
    error,
  };
};

export const useGetOrgNotifications = () => {
  const { id: organizationID } = useReactiveVar(activeOrgVar);
  const { data, loading, error, refetch } = useQuery( ORGANIZATION_NOTIFICATION.PAGINATED,{
    variables: {
      input: { organizationID, pagination: { offset: 0, pageLimit: 10 } },
    },
  });

  const notifications = data?.getPaginatedOrgNotifications;

  return {
    unreadOrgCount: notifications?.organizationNotifications.filter((n) => !n.isRead).length,
    organizationNotifications: notifications?.organizationNotifications || [],
    loading,
    refetch,
    error,
  };
};
