import {
  jobTypeMetadata,
  LARGE_BATCH_MACHINE_TRANSLATION_JOB_TYPE,
  ON_DEMAND_VIDEO_INTERPRETATION_JOB_TYPE,
  REAL_TIME_MACHINE_TRANSLATION_JOB_TYPE,
} from "@lango/common/constants";
import ClientVRISettingsForm from "../forms/clientVRISettings/clientVRIForm";
import { isBuyerApp, isPMApp } from "@lango/common/env";
import ClientMTSettingsForm from "../forms/clientMTSettings/clientMTSettingsForm";

export const buildInitialValues = (
  allJobTypes,
  serviceLinesLoading,
  savedValues,
) =>
  !serviceLinesLoading && savedValues
    ? {
        selectedJobTypes: allJobTypes?.reduce((acc, jobType) => {
          acc[jobType.value] =
            Array.isArray(savedValues) &&
            savedValues.some((job) => job.value === jobType.value);
          return acc;
        }, {}),
      }
    : {};
// Assemble set of available service lines, merging the DB results with the metadata.
export const buildServiceLines = (allJobTypes, organizationID) => {
  // set flag to show settings for 1 machine transaltion service line real time or large batch
  let hasRealTimeTranslation = false;
  allJobTypes?.forEach((jobType) => {
    if (
      isBuyerApp &&
      organizationID > 0 &&
      jobType.label === REAL_TIME_MACHINE_TRANSLATION_JOB_TYPE
    ) {
      hasRealTimeTranslation = true;
    }
  });

  return allJobTypes?.map((jobType) => {
    let additionalProps = {};

    if (
      isPMApp &&
      jobType.label === ON_DEMAND_VIDEO_INTERPRETATION_JOB_TYPE &&
      organizationID > 0
    ) {
      additionalProps = {
        _component: ClientVRISettingsForm,
        component_heading: "Client VRI Settings",
      };
    }

    if (
      isBuyerApp &&
      jobType.label === ON_DEMAND_VIDEO_INTERPRETATION_JOB_TYPE &&
      organizationID > 0
    ) {
      additionalProps = {
        ...additionalProps,
        _component: ClientVRISettingsForm,
        component_heading: "Client VRI Settings",
      };
    }

    if (isBuyerApp && organizationID > 0) {
      if (
        jobType.label === REAL_TIME_MACHINE_TRANSLATION_JOB_TYPE &&
        hasRealTimeTranslation
      ) {
        additionalProps = {
          _component: ClientMTSettingsForm,
          component_heading: "Machine Translation",
        };
        
      } else if (
        jobType.label === LARGE_BATCH_MACHINE_TRANSLATION_JOB_TYPE &&
        !hasRealTimeTranslation
      ) {
        // Only show if the real-time setting hasn't been shown
        additionalProps = {
          _component: ClientMTSettingsForm,
          component_heading: "Machine Translation",
        };
      }
    }

    return {
      ...jobType,
      ...jobTypeMetadata[jobType.label],
      ...additionalProps,
    };
  });
};
