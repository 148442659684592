import classnames from "@lango/common/classnames";
import Checkbox from "@lango/common/features/forms/components/Checkbox";
import themes from "@lango/common/features/themes";
import { useFormikContext } from "formik";
import Button from "./Button";
import { CheckboxSettings } from "./CheckboxSettings";
import Flex from "../../tables/components/Flex";
const CheckboxList = ({ items, name, label, hideCheckBoxIcon}) => {
  // todo: get theme from context
  const curTheme = themes.lango;

  const { setValues, values, setFieldValue } = useFormikContext();

  const selectAll = () => {
    setValues({
      ...values,
      [name]: Object.keys(values[name]).reduce(
        (acc, key) => ((acc[key] = true), acc),
        {},
      ),
    });
  };

  const deselectAll = () => {
    setValues({
      ...values,
      [name]: Object.keys(values[name]).reduce(
        (acc, key) => ((acc[key] = false), acc),
        {},
      ),
    });
  };

  // Assemble checkboxes into groups.
  const groupedItems = {};
  items.forEach((item) => {
    if (!groupedItems[item.group]) {
      groupedItems[item.group] = [];
    }
    groupedItems[item.group].push(item);
  });

  return (
    <div>
      <div className={"flex justify-between items-center"}>
        <h2 className={"text-lg font-semibold"}>{label}</h2>
        {!hideCheckBoxIcon && (
          <div>
            <Button
              type="button"
              onClick={selectAll}
              textButton
              extraClasses={"p-2 text-sm"}
            >
              Select All
            </Button>
            <Button
              type="button"
              onClick={deselectAll}
              textButton
              extraClasses={"p-2 text-sm"}
            >
              Deselect All
            </Button>
          </div>
        )}
      </div>
      {Object.keys(groupedItems).map((group) => (
        <div key={group}>
          <h3 className={"text-base font-semibold mb-3 mt-6"}>{group || ""}</h3>
          {groupedItems[group].map((item, i) => (
            <div className={"py-3"} key={item.value}>
              <Flex justify="between">
                <Checkbox
                  extraClasses={classnames(
                    curTheme.animation,
                    curTheme.bgHoverPrimaryLight,
                  )}
                  checked={values[name][item.value]}
                  onChange={(e) => {
                    setFieldValue(
                      `${name}.${item.value}`,
                      e?.target?.checked,
                    ).catch((e) => {
                      console.error(e);
                    });
                  }}
                  name={name}
                  value={item.value}
                  label={item.label}
                  description={item.description}
                  style={{
                    // Conditionally hide the checkbox icon
                    display: hideCheckBoxIcon ? "none" : "inline-block",
                  }}
                />
                {item._component && item.component_heading && (
                  <CheckboxSettings
                    _component={item._component}
                    heading={item.component_heading}
                  />
                )}
              </Flex>
              {i < groupedItems[group].length - 1 && <hr className={"mt-6"} />}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default CheckboxList;
