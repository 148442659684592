import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { useAcceptVendorInviteCtx } from "@lango/common/context";
import environmentVars from "@lango/common/env";
import { toastSuccess, toastWarning } from "@lango/common/features";
import { ACCEPT_VENDOR_INVITATION } from "@lango/common/queries";

import { clearOrgLogoInfo, initializeUserData, invitedLSPVar } from "../apollo";
import {
  useProvisionUserAndRefetchLSPs,
  useUpdateUserOrgLogin,
} from "../features/auth/hooks";
import { AUTH_ROUTES, VENDOR_ROUTES } from "../routes";

export const useAcceptVendorInvitation = () => {
  const [acceptVendorInvitation, { loading: loadingAcceptInvite }] =
    useMutation(ACCEPT_VENDOR_INVITATION);
  const navigate = useNavigate();
  const { redirectMode, handleChangOnboardingData } =
    useAcceptVendorInviteCtx();
  const { findOrRegisterUser, loading } = useProvisionUserAndRefetchLSPs();
  const handleError = useHandleAcceptInviteError();
  const { handleUpdateUserOrgLogin } = useUpdateUserOrgLogin();

  const acceptInviteAndInitializeUser = useCallback(
    async (code, orgID) => {
      try {
        const { data } = await acceptVendorInvitation({
          variables: { code, orgID, sourceAppName: environmentVars.appName },
        });
        const response = data?.acceptVendorInvitation;
        const organizationID = response?.organizationID;
        const lspID = response?.lspID;
        lspID && invitedLSPVar(lspID);
        toastSuccess("Invitation Accepted");
        const userData = await findOrRegisterUser({ organizationID });
        await handleUpdateUserOrgLogin(organizationID, lspID);
        initializeUserData(userData);
        const nextStep = response?.nextStep;
        ["step1", "step2", "step3"].includes(nextStep)
          ? handleChangOnboardingData(response, nextStep)
          : navigate(VENDOR_ROUTES.REQUESTS);
      } catch (error) {
        handleError(error, redirectMode);
      }
    },
    [acceptVendorInvitation],
  );

  return {
    acceptInviteAndInitializeUser,
    loading: loadingAcceptInvite || loading,
  };
};

export function useHandleAcceptInviteError() {
  const navigate = useNavigate();

  function handleError(error, redirectMode = false) {
    toastWarning(error?.message || "Error accepting invitation");
    console.error(error);
    const to = redirectMode ? AUTH_ROUTES.LOGOUT : "/";
    redirectMode && clearOrgLogoInfo();
    navigate(to);
  }

  return handleError;
}
