import { signOut } from "aws-amplify/auth";

import { isValidNameSlug } from "@lango/common/components/Helpers";
import { resetHubspotWidget } from "@lango/common/components/HubspotChatWidget/utils";
import envs, { isVendorApp } from "@lango/common/env";
import { AUTH_ROUTES, COMMON_ROUTES } from "@lango/common/routes";

import { BUYER_APP, LocalStorageKeys, VENDOR_APP } from "../constants";
import { makePersistedVar } from "./makePersistedVar";

export const authStateVar = makePersistedVar("authState", "signIn");
export const userIDVar = makePersistedVar("userID", null);
export const usernameVar = makePersistedVar("username", null);
export const userFirstNameVar = makePersistedVar("userFirstName", null);
export const userLastNameVar = makePersistedVar("userLastName", null);
export const userGenderVar = makePersistedVar("userGender", null);
export const preferredTimezoneVar = makePersistedVar("preferredTimezone", null);
export const userOrgsVar = makePersistedVar("userOrgs", null);
export const activeOrgVar = makePersistedVar("activeOrg", null);
export const logoutSlugVar = makePersistedVar("logoutSlug", "");
export const federatedLoginVar = makePersistedVar("federatedLogin", false);
export const userNotificationsEnabledVar = makePersistedVar(
  "userNotificationsEnabled",
  null,
);
export const activeLSPVar = makePersistedVar("activeLSP", null);
export const invitedLSPVar = makePersistedVar("invitedLSP", null);
export const orgLogoInfoVar = makePersistedVar("orgLogoInfo", null);
export const pendingInvitationsVar = makePersistedVar(
  "pendingInvitations",
  null,
);

export const updateUser = ({
  firstName,
  lastName,
  preferredTimezone,
  gender,
}) => {
  userFirstNameVar(firstName);
  userLastNameVar(lastName);
  userGenderVar(gender);
  preferredTimezone && preferredTimezoneVar(preferredTimezone);
};

const { appName } = envs;

const appsWithCustomLogout = [BUYER_APP, VENDOR_APP];
export const isAppWithCustomLogout = () =>
  appsWithCustomLogout.includes(appName);

// set org logout slug var
const setLogoutSlug = () => {
  const activeOrg = activeOrgVar();
  const orgNameSlug =
    isAppWithCustomLogout() &&
    activeOrg?.customLoginPage &&
    isValidNameSlug(activeOrg?.nameSlug)
      ? activeOrg.nameSlug
      : "";

  logoutSlugVar(orgNameSlug);
};

export const updateActiveOrg = ({ newOrg }) => {
  activeOrgVar(newOrg);
  orgLogoInfoVar({ id: newOrg.id });
  setLogoutSlug();
  activeLSPVar({});
};

export const clearOrgLogoInfo = () => {
  orgLogoInfoVar(null);
};

export const updateOrgLogoInfo = (id) => {
  orgLogoInfoVar({ id });
};

export const updateActiveLSP = (lsp) => {
  activeLSPVar(lsp);
};

export const initializeUserData = (user) => {
  const pendingInvitations = user?.pendingInvitations || [];

  if (user?.organizations?.length === 0 && pendingInvitations.length === 0) {
    throw new Error("No organizations found for user");
  }

  usernameVar(user?.email);
  userIDVar(user?.id);
  userFirstNameVar(user?.firstName);
  userLastNameVar(user?.lastName);
  userGenderVar(user?.gender);
  userOrgsVar(user?.organizations);
  activeOrgVar(user?.activeOrganization || { id: null });
  userNotificationsEnabledVar(user?.notifications);
  orgLogoInfoVar({ id: user?.activeOrganization?.id });
  authStateVar("signedIn");
  setLogoutSlug();
  preferredTimezoneVar(user?.preferredTimezone);
  pendingInvitationsVar(pendingInvitations);
};

export const resetStore = () => {
  usernameVar(null);
  userIDVar(null);
  userFirstNameVar(null);
  userLastNameVar(null);
  userOrgsVar(null);
  activeOrgVar(null);
  userNotificationsEnabledVar(null);
  orgLogoInfoVar(null);
  authStateVar("signIn");
  removeFromLocalStorage("logoutSlug");
  activeLSPVar(null);
  federatedLoginVar(false);
  pendingInvitationsVar(null);
};

export const clearLocalStorage = () => {
  LocalStorageKeys.map((key) => removeFromLocalStorage(key));
};

export const logout = async () => {
  clearLocalStorage();
  resetHubspotWidget();
  await signOut();
  const isFederatedLogin = federatedLoginVar();
  resetStore();
  return !isFederatedLogin && isVendorApp;
};

const removeFromLocalStorage = (key) => {
  localStorage.removeItem(key);
};

export const getLoginPath = (from, orgSlug = "") => {
  const org = orgSlug || (isAppWithCustomLogout() && logoutSlugVar());
  let params = new URLSearchParams();
  if (from) {
    params.set("from", from);
  }
  if (org) {
    params.set("org", org);
  }
  return `${AUTH_ROUTES.LOGIN}?${params.toString()}`;
};

export const getLogoutPath = (from) => {
  let params = new URLSearchParams();
  if (from) {
    params.set("from", from);
  }
  return `${AUTH_ROUTES.LOGOUT}?${params.toString()}`;
};

export const getInitialPath = (from) => {
  const hasOrganizations = userOrgsVar() !== null;
  const hasPendingInvitations = pendingInvitationsVar()?.length > 0;
  if (!hasOrganizations && hasPendingInvitations) {
    return COMMON_ROUTES.INVITATIONS;
  }
  return from || "/";
};
