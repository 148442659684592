import { useReactiveVar } from "@apollo/client";

import { activeOrgVar, updateActiveLSP } from "@lango/common/apollo/localState";
import Dropdown from "@lango/common/components/dropdown";
import { toastError } from "@lango/common/features/alerts/functions/toast";
import { useUpdateUserOrgLogin } from "@lango/common/features/auth/hooks";
import { useIsAuthenticated } from "@lango/common/features/auth/hooks/useIsAuthenticated";
import { useGetOrgLSPs } from "@lango/common/hooks";
import LSPsDropdownList from "./LSPsDropdownList";
import Logo from "./Logo";

const HeaderLogo = () => {
  const activeOrg = useReactiveVar(activeOrgVar);
  const { orgLSPs } = useGetOrgLSPs();
  const { isAuthenticated } = useIsAuthenticated();
  const { handleUpdateUserOrgLogin } = useUpdateUserOrgLogin();

  const handleLspChange = async (lsp) => {
    try {
      await handleUpdateUserOrgLogin(activeOrg.id, lsp.id);
      updateActiveLSP(lsp);
    } catch (error) {
      toastError("Failed to change active LSP.");
    }
  };

  const menuList = orgLSPs
    ?.map((lsp) => ({
      onClick: () => handleLspChange(lsp),
      ...lsp,
    }))
    .filter(Boolean);

  const isLSPsDropdownVisible = menuList?.length >= 1 && isAuthenticated;

  return (
    <Dropdown className="left-0 right-auto" showTriangleIcon={false}>
      <Dropdown.Button disabled={isLSPsDropdownVisible}>
        <Logo isLSPsDropdownVisible={isLSPsDropdownVisible} />
      </Dropdown.Button>
      <Dropdown.Menu className="w-60">
        <LSPsDropdownList menuItems={menuList} />
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default HeaderLogo;
