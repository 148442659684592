import { useQuery} from "@apollo/client";

import { GET_CLIENT_PROFILE_ID } from "../queries";
import { activeLSPVar, activeOrgVar } from "../apollo";
import { toastError } from "../features";

export const useFetchClientProfileID= ()=> {
  const orgID = activeOrgVar()?.id;
  const lspID = activeLSPVar()?.id;

  const { loading, data, error } = useQuery(GET_CLIENT_PROFILE_ID, {
    variables: {
      orgID,
      lspID,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
    onError: (error) => toastError(error?.message),
  });

  return {
    clientID: data?.clientProfileSettings.id,
    loading,
    error,
  };
}
