import { RiLockFill } from "react-icons/ri";
export const ORG_SIGNIN_ERROR = "Error signing in to organization: ";
export const ORG_FORM_HEADING = "Organization Sign In";
export const HEADING = "Organization Sign In";
export const PM_APP = "project management";
export const BUYER_APP = "buyer";
export const VENDOR_APP = "vendor";
export const ORG_ERROR = "Organization Error";
export const INTENTO_PROVIDER = "intento";
export const LocalStorageKeys = [
  "authState",
  "federatedLogin",
  "userID",
  "userFirstName",
  "userOrgs",
  "activeOrg",
  "userNotificationsEnabled",
  "username",
];

// genders
export const NON_BINARY = "non-binary";
export const DEFAULT_GENDER = { value: 0, label: "No Preference" };
export * from "./errors";
export * from "./jobTypes";

// input fields
export const INPUT_DATE_FORMAT = "YYYY-MM-DD";

// File Upload Fields
export const allowedFileTypesHT = {
  "text/plain": [".txt"],
  "text/csv": [".csv"],
  "application/pdf": [".pdf"],
  "application/msword": [".doc"],
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
    ".docx",
  ],
  "application/vnd.ms-excel": [".xls"],
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
    ".xlsx",
  ],
  "application/vnd.ms-powerpoint": [".ppt"],
  "application/vnd.openxmlformats-officedocument.presentationml.presentation": [
    ".pptx",
  ],
};
export const allowedFileTypesHTDescription =
  "Microsoft Office Files, .txt., .pdf, .csv";

export const allowedFileTypesMT = {
  ...allowedFileTypesHT,
  "text/html": [".htm", ".html"],
  "text/xml": [".xml"],
  "application/xml": [".xml"],
  "image/jpeg": [".jpg", ".jpeg"],
  "image/png": [".png"],
};

export const allowedFileTypesBulkVendors = {
  "text/csv": [".csv"],
};

export const allowedFileTypesMTDescription = `${allowedFileTypesHTDescription}, .html, .xml`;

export const DATE_RANGE = "dateRange";

// call recording modes
export const DONT_RECORD_BY_DEFAULT = 1;
export const RECORD_BY_DEFAULT = 2;
export const ALWAYS_RECORD_CALLS = 3;
export const NEVER_RECORD_CALLS = 4;

// call recording info text
export const ALWAYS_RECORD_CALLS_INFO_TEXT =
  "Your org administrator has required that all calls be recorded";
export const NEVER_RECORD_CALLS_INFO_TEXT =
  "Your org administrator has disabled all call recordings";
export const ENABLE_CALL_RECORDING_INFO_TEXT =
  "Contact your Sales representative should you want the call recording function enabled";
export const ENABLE_SCREEN_SHARE_INFO_TEXT =
  "Contact your Sales representative should you want the screen share function enabled";
export const ENABLE_VIRTUAL_BACKGROUND_INFO_TEXT =
  "Contact your Sales representative should you want the virtual background function enabled";
export const ENABLE_GLOSSARY_SUPPORT_INFO_TEXT =
  "Contact your Sales representative should you want the  glossary support function enabled";
export const ENABLE_JOB_WORD_COUNT_LIMIT_INFO_TEXT =
  "Contact your Sales representative should you want the job word count limit function enabled";
export const ENABLE_AUTO_DELETE_DOCUMENT_INFO_TEXT =
  "Contact your Sales representative should you want the auto delete document function enabled";
export const LOCK_ICON = <RiLockFill className="w-6 h-5" />;

// download source files endpoint
export const DOWNLOAD_SOURCE_FILES = "download_source_files";

//  composition video status slugs
export const COMPOSITION_VIDEO_STATUSES = {
  CREATED: "created",
  IN_PROGRESS: "in_progress",
  COMPLETED: "completed",
  FAILED: "failed",
};

// composition completion event names
export const compositionCompletedEventName = "composition-completed";

// roles
export const ADMIN = "Admin";
export const MEMBER = "Member";

export const INVITATION_MESSAGES = {
  logout_to_accept: "Please log out to accept the invitation",
};

// vendor types
export const INDIVIDUAL = "Individual";
export const AGENCY = "Agency";

export const VENDOR_INDIVIDUAL_TYPE = 1;
export const VENDOR_AGENCY_TYPE = 2;

// organization types
export const ORGANIZATION_TYPE = {
  CLIENT: "Client",
  LSP: "LSP",
  VENDOR: "Vendor",
};

export const APPROVED = "Approved";
export const DECLINED = "Declined";

export const VENDOR_TYPES = [
  { value: VENDOR_INDIVIDUAL_TYPE, label: INDIVIDUAL },
  { value: VENDOR_AGENCY_TYPE, label: AGENCY },
];

export function isIndividualType(type) {
  return type === VENDOR_INDIVIDUAL_TYPE;
}

export function isAgencyType(type) {
  return type === VENDOR_AGENCY_TYPE;
}

export const RATE_UNITS = [
  { label: "Per Minute", display: "minute" },
  { label: "Per Hour", display: "hour" },
];

export const PRO_RATA = "pro-rata";
export const ROUNDING = [
  { label: "Pro Rata", value: PRO_RATA },
  { label: "Up", value: "up" },
  { label: "Down", value: "down" },
];

export const ROUNDING_AMOUNT = [
  { label: "15", value: 15 },
  { label: "30", value: 30 },
];

export const EFFECTIVE_TIME_FRAME = [
  { label: "15", value: 15 },
  { label: "30", value: 30 },
  { label: "45", value: 45 },
];

export { VENDOR_STATUS } from "./vendor";
