import * as yup from "yup";

import { PRO_RATA } from "../constants";

const duplicateLanguagePairs = "Language pairs cannot be duplicated";
const errTimeFrame = "End time must be greater than start time";
const errSameLanguages = "Source language shouldn't match the target language";

const uniqueLanguagePairs = function (languagePairs) {
  const seenPairs = new Set();
  for (let i = 0; i < languagePairs?.length; i++) {
    const { sourceLanguage, targetLanguage } = languagePairs[i];

    if (sourceLanguage && targetLanguage) {
      const pairKey = `${sourceLanguage.value}-${targetLanguage.value}`;
      if (seenPairs.has(pairKey)) {
        return this.createError({
          path: `languagePairs[${i}].targetLanguage`,
          message: "Duplicate language pairs are not allowed",
        });
      }
      seenPairs.add(pairKey);
    }
  }
  return true;
};

const validateRatePairs = function (incrementalRates) {
  const seenPairs = new Set();
  for (let i = 0; i < incrementalRates?.length; i++) {
    const { effectiveTimeFrom, effectiveTimeTo } = incrementalRates[i];

    if (effectiveTimeFrom && effectiveTimeTo) {
      const pairKey = `${effectiveTimeFrom.value}-${effectiveTimeTo.value}`;
      if (seenPairs.has(pairKey)) {
        return this.createError({
          path: `incrementalRates[${i}].effectiveTimeTo`,
          message: "Duplicate rate pairs are not allowed",
        });
      }
      seenPairs.add(pairKey);
    }
  }
  return true;
};

export const validateVendorRateSheet = yup.object().shape({
  name: yup.string().required("Rate name is required"),
  jobType: yup
    .object()
    .shape({ value: yup.string().required(), label: yup.string().required() })
    .nullable()
    .required("Job type is required"),
  languagePairs: yup
    .array()
    .of(
      yup.object().shape({
        sourceLanguage: yup
          .object()
          .shape({
            value: yup.string().required(),
            label: yup.string().required(),
          })
          .nullable()
          .required("Source language is required"),
        targetLanguage: yup
          .object()
          .shape({
            value: yup.string().required(),
            label: yup.string().required(),
          })
          .nullable()
          .required("Target language is required")
          .test("unique-pair", errSameLanguages, function (targetLanguage) {
            const { sourceLanguage } = this.parent;
            if (!sourceLanguage || !targetLanguage) return true;
            return sourceLanguage?.value !== targetLanguage?.value;
          }),
      }),
    )
    .test("unique-pairs", duplicateLanguagePairs, uniqueLanguagePairs),
  rate: yup
    .number()
    .typeError("Rate must be a number")
    .positive("Rate must be greater than zero")
    .required("Rate is required"),
  rateUnit: yup
    .object()
    .shape({ value: yup.string().required(), label: yup.string().required() })
    .nullable()
    .required("Unit is required"),
  rounding: yup
    .object()
    .shape({ value: yup.string().required(), label: yup.string().required() })
    .nullable()
    .required("Rounding is required"),
  amount: yup
    .object()
    .shape({ value: yup.string().required(), label: yup.string().required() })
    .nullable()
    .when("rounding.value", {
      is: PRO_RATA,
      then: yup.object().nullable(),
      otherwise: yup.object().nullable().required("Amount is required"),
    }),
  incrementalRates: yup
    .array()
    .of(
      yup.object().shape({
        incrementalRate: yup
          .number()
          .typeError("Incremental rate must be a number")
          .positive("Incremental rate must be greater than zero")
          .required("Incremental rate is required"),
        effectiveTimeFrom: yup
          .object()
          .shape({
            value: yup.number().required(),
            label: yup.string().required(),
          })
          .nullable()
          .required("Start time is required"),
        effectiveTimeTo: yup
          .object()
          .shape({
            value: yup.number().required(),
            label: yup.string().required(),
          })
          .nullable()
          .required("End time is required")
          .test("rate-pair", errTimeFrame, function (effectiveTimeTo) {
            const { effectiveTimeFrom } = this.parent;
            return effectiveTimeFrom?.value < effectiveTimeTo?.value;
          }),
      }),
    )
    .nullable()
    .test("rate-pairs", "", validateRatePairs),
});

const isLanguageRequired = (language, otherLanguage) => {
  return !!language || !otherLanguage;
};

export const validateVendorRateSheetFilter = yup.object().shape({
  languageFrom: yup
    .object()
    .shape({ value: yup.string(), label: yup.string() })
    .nullable()
    .test(
      "when-required",
      "Source language is required",
      function (languageFrom) {
        const { languageTo } = this.parent;
        return isLanguageRequired(languageFrom, languageTo);
      },
    ),
  languageTo: yup
    .object()
    .shape({ value: yup.string(), label: yup.string() })
    .nullable()
    .test(
      "when-required",
      "Target language is required",
      function (languageTo) {
        const { languageFrom } = this.parent;
        return isLanguageRequired(languageTo, languageFrom);
      },
    )
    .test("unique-pair", errSameLanguages, function (languageTo) {
      const { languageFrom } = this.parent;
      return languageFrom && languageTo
        ? languageFrom.value !== languageTo.value
        : true;
    }),
});
