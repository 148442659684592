import Pusher from "pusher-js";
import React, { createContext, useState } from "react";

import {
  PUSHER_APP_CLUSTER,
  PUSHER_APP_KEY,
  PUSHER_DEBUG,
} from "./pusherConfig";

export const PusherContext = createContext(
  /** @type {import("pusher-js").default | null} */ (null),
);

export const PusherProvider = ({ children }) => {
  if (!PUSHER_APP_KEY || !PUSHER_APP_CLUSTER) {
    console.error("invalid pusher config");
  }
  const [client] = useState(
    new Pusher(PUSHER_APP_KEY, {
      cluster: PUSHER_APP_CLUSTER,
      logToConsole: PUSHER_DEBUG === true,
    }),
  );

  return (
    <PusherContext.Provider value={client}>{children}</PusherContext.Provider>
  );
};
