import { useModal } from "@lango/common/context";
import Flex from "../../tables/components/Flex";
import FormControl from "../components/FormControl";
import { Form, useFormikContext } from "formik";
import {
  ENABLE_SCREEN_SHARE_INFO_TEXT,
  ENABLE_VIRTUAL_BACKGROUND_INFO_TEXT,
} from "@lango/common/constants";
import { isPMApp } from "@lango/common/env";

const ClientVRISettingFields = ({
  isEnableScreenShare,
  isEnableVirtualBackground,
}) => {
  const { isSubmitting, dirty } = useFormikContext();
  const { hideModal } = useModal();
  return (
    <Form className="space-y-6">
      {isPMApp ? (
        <>
          <FormControl
            control="toggle"
            name="isScreenShareFeatureEnabled"
            label="Enable Screen Share"
            disabled={isSubmitting}
          />
          <FormControl
            control="toggle"
            name="isVirtualBackgroundFeatureEnabled"
            label="Enable Virtual Background"
            disabled={isSubmitting}
          />
        </>
      ) : (
        <>
          <FormControl
            control="toggle"
            name="enableScreenShare"
            label="Enable Screen Share"
            disabled={!isEnableScreenShare || isSubmitting}
            infotext={
              !isEnableScreenShare ? ENABLE_SCREEN_SHARE_INFO_TEXT : null
            }
          />
          <FormControl
            control="toggle"
            name="enableVirtualBackground"
            label="Enable Virtual Background"
            disabled={!isEnableVirtualBackground || isSubmitting}
            infotext={
              !isEnableVirtualBackground
                ? ENABLE_VIRTUAL_BACKGROUND_INFO_TEXT
                : null
            }
          />
        </>
      )}

      <Flex justify="center" extraClasses="gap-4">
        <FormControl
          control="button"
          type="button"
          text="Cancel"
          importance="secondary"
          onClick={hideModal}
          disabled={isSubmitting}
        />
        <FormControl
          control="button"
          type="submit"
          importance="primary"
          text="Save"
          disabled={isSubmitting || !dirty}
          showLoader={isSubmitting}
        />
      </Flex>
    </Form>
  );
};

export default ClientVRISettingFields;
