import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import { UPDATE_USER_LOGIN } from "./queries";

export const useUpdateUserOrgLogin = () => {
  const [updateUserOrgLogin, { loading }] = useMutation(UPDATE_USER_LOGIN);

  const handleUpdateUserOrgLogin = useCallback(
    async (orgID, lspID = null) => {
      await updateUserOrgLogin({
        variables: {
          organizationID: orgID,
          lspID,
        },
      });
    },
    [updateUserOrgLogin],
  );

  return { handleUpdateUserOrgLogin, loading };
};
