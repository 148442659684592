import {
  DATE_RANGE,
  HUMAN_TRANSLATION,
  ON_DEMAND_INTERPRETATION_JOB_TYPE_CATEGORY,
  isOnDemandInterpretationJobType,
} from "@lango/common/constants";

import envs, { isBuyerApp } from "@lango/common/env";
import moment from "moment-timezone";

export * from "./rate-sheet";

// Note: This list comes from the backend mapping here: graph/protected/resolvers/project_resolvers.go
export const filterKeys = {
  billingReference: "billingReference",
  dateRange: "dateRange",
  dueDate: "dueDate",
  htFutureJobs: "htFutureJobs",
  jobStatusSlug: "jobStatusSlug",
  jobType: "jobType",
  labels: "labels",
  languageFrom: "languageFrom",
  languageTo: "languageTo",
  requestType: "requestType",
  status: "status",
  submittedDate: "submittedDate",
};

export const filterTypes = {
  common: "commonFilters",
  custom: "customFilters",
};
export const exactMatchFilters = [
  filterKeys.status,
  filterKeys.requestType,
  filterKeys.dateRange,
];
export const fuzzyMatchFilters = [
  filterKeys.billingReference,
  filterKeys.labels,
  filterKeys.languageFrom,
  filterKeys.languageTo,
];

export const filterType = {
  [filterKeys.billingReference]: filterTypes.common,
  [filterKeys.labels]: filterTypes.common,
  [filterKeys.languageFrom]: filterTypes.common,
  [filterKeys.languageTo]: filterTypes.common,
  [filterKeys.requestType]: filterTypes.common,
  [filterKeys.status]: filterTypes.common,
  // Custom Filters
  [filterKeys.labels]: filterTypes.custom,
  [filterKeys.dateRange]: filterTypes.custom,
};
export const emptyFilters = {
  [filterTypes.common]: [],
  [filterTypes.custom]: [],
};

export const inviteUserRequest = (values = {}) => {
  const { emails, role, organizationID } = values;
  return {
    variables: {
      input: {
        emails: emails?.map((value) => value?.email),
        roleID: role?.value,
        organizationID,
        sourceAppName: envs.appName,
      },
    },
  };
};

const humanTranslationFilter = {
  key: "jobType",
  values: [HUMAN_TRANSLATION],
};

const jobStatusSlugFilter = {
  key: "jobStatusSlug",
  values: ["created", "in_progress"],
};

const htjobsFilter = {
  key: "htFutureJobs",
  values: ["true"],
};

const ondemandFilter = {
  key: "jobType",
  values: [ON_DEMAND_INTERPRETATION_JOB_TYPE_CATEGORY],
};

const htOndemandFilter = {
  key: "jobType",
  values: [HUMAN_TRANSLATION, ON_DEMAND_INTERPRETATION_JOB_TYPE_CATEGORY],
};

export const homePageJobFilters = {
  commonFilters: [htOndemandFilter, jobStatusSlugFilter],
  customFilters: [htjobsFilter],
};

export const homePageHTJobFilters = {
  commonFilters: [humanTranslationFilter, jobStatusSlugFilter],
  customFilters: [htjobsFilter],
};

export const homePageOnDemandJobFilters = {
  commonFilters: [ondemandFilter, jobStatusSlugFilter],
  customFilters: [],
};

/**
 * Helper function - updateLanguagePairRequest
 *
 * This function generates a request object for updating a linguist language pair based on the provided props.
 *
 * @param {Object} props - The props to be used for updating the linguist language pair.
 * @returns {Object} The request object for updating the linguist language pair.
 */
export const updateLanguagePairRequest = (props) => {
  const { id, jobType, sourceLanguage, targetLanguage, isActive } = props;
  return {
    variables: {
      request: {
        id,
        jobTypeID: jobType?.value,
        languageFromID: sourceLanguage?.value,
        languageToID: targetLanguage?.value,
        isActive,
      },
    },
  };
};

/**
 * Helper function - preparePaginatedJobRoutingsRequest
 *
 * This function generates a request object for preparing paginated job routings based on the provided props.
 *
 * @param {Object} props - The props to be used for preparing the job routings request.
 * @returns {Object} The request object for preparing the job routings.
 */
export const preparePaginatedJobRoutingsRequest = (props) => {
  const { pageIndex, pageSize, sortBy, lspID } = props;

  return {
    variables: {
      input: {
        lspID,
        pagination: {
          offset: pageIndex * pageSize,
          pageLimit: pageSize,
        },
        ...prepareSortBy(sortBy),
      },
    },
  };
};

/**
 * Helper function - prepareSortBy
 *
 * This function generates sorting parameters based on the provided sortBy array.
 *
 * @param {Array} sortBy - The array containing sorting information.
 * @returns {Object} The sorting parameters.
 */
export const prepareSortBy = (sortBy) => {
  return sortBy?.length > 0
    ? { sortBy: sortBy[0].id, sortDir: sortBy[0].desc ? "DESC" : "ASC" }
    : {};
};

/**
 * Renders helper - createJobRoutingRequest Function
 *
 * This function generates a request object for creating a job routing based on the provided values.
 *
 * @param {Object} values - The values to be used for creating the job routing.
 * @returns {Object} The request object for creating the job routing.
 */
export const createJobRoutingRequest = (values = {}) => {
  const {
    lspID,
    jobType,
    sourceLanguage,
    targetLanguage,
    useLangoCrowd,
    industry,
    enableGeneralRouting,
  } = values;
  return {
    variables: {
      input: {
        lspID,
        useLangoCrowd: useLangoCrowd || false,
        jobTypeID: jobType?.value,
        languageFromID: sourceLanguage?.value,
        languageToIDs: targetLanguage?.map((language) => language?.value),
        industryIDs: industry?.map((industry) => industry?.value),
        enableGeneralRouting,
      },
    },
  };
};

/**
 * Renders helper - updateJobRoutingRequest
 *
 * This function generates a request object for updating a job routing based on the provided values.
 *
 * @param {Object} values - The values to be used for updating the job routing.
 * @returns {Object} The request object for updating the job routing.
 */
export const updateJobRoutingRequest = (values = {}) => {
  const {
    id,
    jobType,
    sourceLanguage,
    targetLanguage,
    useLangoCrowd,
    industry,
    enableGeneralRouting,
  } = values;
  return {
    variables: {
      input: {
        id,
        useLangoCrowd,
        jobTypeID: jobType?.value,
        languageFromID: sourceLanguage?.value,
        languageToIDs: targetLanguage?.map((language) => language?.value),
        industryIDs: industry?.map((industry) => industry?.value),
        enableGeneralRouting,
      },
    },
  };
};

/**
 * Renders helper - createRoutingLevelRequest
 *
 * This function generates a request object for creating a routing level based on the provided values.
 *
 * @param {Object} values - The values to be used for creating the routing level.
 * @returns {Object} The request object for creating the routing level.
 */
export const createRoutingLevelRequest = (values = {}) => {
  const { levelName, jobRoutingID, vendors } = values;

  return {
    variables: {
      input: {
        levelName,
        jobRoutingID,
        vendorIDs: vendors?.map((vendor) => vendor?.value),
      },
    },
  };
};

/**
 * Renders helper - assignVendorsRequest
 *
 * This function generates a request object for assigning vendors based on the provided values.
 *
 * @param {object} values - The values used to assigning the vendors.
 * @returns {object} - The request object for assigning vendors.
 */
export const assignVendorsRequest = (values = {}) => {
  const { routingLevelID, vendors } = values;

  return {
    variables: {
      routingLevelID,
      vendorIDs: vendors?.map((vendor) => vendor?.value),
    },
  };
};

/**
 * Renders helper - prepareHTEditTaskRequest
 *
 * This function generates a request object for editing a task.
 *
 * @param {Object} props - The values used to edit task.
 * @returns {Object} - The request object for HT edit task.
 */
export const prepareHTEditTaskRequest = (props = {}) => {
  const { id: taskID, rate, taskType, vendor, targetDate } = props;
  return {
    variables: {
      input: {
        taskID,
        rate: rate || 0,
        taskTypeID: taskType?.value,
        vendorID: vendor?.value,
        targetDate: targetDate ? moment.utc(targetDate) : undefined,
      },
    },
  };
};

/**
 * Renders helper - prepareDeleteJobRequest
 *
 * This function generates a request object for deleting a job.
 *
 * @param {number} jobID - The ID of the job to be deleted.
 * @returns {Object} - The request object for deletion job.
 */
export const prepareDeleteJobRequest = (jobID) => ({
  variables: {
    jobIDs: [jobID],
  },
});

/**
 * Renders helper - prepareEditJobRequest
 *
 * This function generates a request object for editing a job.
 *
 * @param {Object} values - The values used to edit job.
 * @param {string} jobID - The ID of the job to be edited.
 * @returns {Object} - The request object for editing job.
 */
export const prepareEditJobRequest = (values, jobID) => {
  const requestData = isOnDemandInterpretationJobType(values?.jobtype)
    ? prepareOnDemandJobData(values)
    : prepareHTJobData(values);

  return { variables: { input: { jobID, ...requestData } } };
};

/**
 * Renders helper - prepareHTJobData
 *
 * This function generates a request object for editing a HT job.
 *
 * @param {Object} values - The values prepare for HT job.
 * @returns {Object}  The object has HT job data to be edited.
 */
const prepareHTJobData = (values) => ({
  jobStatusID: values?.jobStatus?.value,
  languageFromID: values?.sourceLanguage?.value,
  languageToID: values?.targetLanguage?.value,
  estimatedPrice: values?.estimatedPrice || 0.0,
});

/**
 * prepareOnDemandJobData Function
 *
 * This function generates a request object for editing an on-demand job.
 *
 * @param {Object} values - The values prepared for on-demand job
 * @returns {Object} The object has on-demand job data to be edited.
 */
const prepareOnDemandJobData = (values) => ({
  jobStatusID: values?.jobStatus?.value,
  internalNotes: values?.internalNotes,
  customerNotes: values?.customerNotes,
});
/**

 * Renders helper - clientMTUsageRequest
 *
 * This function generates a request object for client MT usage based on the provided values.
 *
 * @param {number} clientProfileID - The clientProfileID to be used to fetch client MT usage for specific client.
 * @param {string} filter - The filter to be used for filter.
 * @returns {Object} The request object for client MT usage.
 */
export const clientMTUsageRequest = (clientProfileID, filter) => ({
  input: {
    clientProfileID,
    filtersReq: {
      commonFilters: [],
      customFilters: [{ key: DATE_RANGE, values: [filter] }],
    },
  },
});

/**
 * Renders helper - preparePaginatedJobTaskRequest
 *
 * This function generates a request object for paginated job task.
 *
 * @param {Object} props - The props object containing query parameters.
 * @returns {Object} - The object containing prepared request variables.
 */
export const preparePaginatedJobTaskRequest = (props = {}) => {
  const { pageIndex, pageSize, sortBy, jobID, organizationID } = props;
  return {
    variables: {
      organizationID,
      jobID,
      offset: pageIndex * pageSize,
      pageLimit: pageSize,
      sourceAppName: envs.appName,
      ...prepareSortBy(sortBy),
    },
  };
};

/**
 * Helper function - createVendorProfileLanguages
 *
 * This function generates a request object for creating a vendor profile language on the provided values.
 *
 * @param {Object} values - The values to be used for the vendor profile language request.
 * @returns {Object} The request object for creating vendor profile language.
 */
export const createVendorProfileLanguages = (values = {}) => {
  const {
    organizationID,
    lspID,
    sourceLanguage,
    targetLanguage,
    industry,
    jobType,
    appName,
  } = values;

  return {
    variables: {
      request: {
        organizationID,
        appName,
        lspID,
        languageFromID: sourceLanguage?.value,
        languageToID: targetLanguage?.value,
        industryID: industry?.value,
        jobTypeID: jobType?.value,
      },
    },
  };
};

/**
 * Helper function - prepareVendorProfileLanguageRequest
 *
 * This function generates a request object for preparing paginated vendor profile languages based on the provided props.
 *
 * @param {Object} props - The props to be used for preparing the vendor profile languages request.
 * @returns {Object} The request object for preparing the vendor profile languages.
 */
export const prepareVendorProfileLanguageRequest = (props = {}) => {
  const { pageIndex, pageSize, organizationID, lspID } = props;
  return {
    variables: {
      offset: pageIndex * pageSize,
      pageLimit: pageSize,
      organizationID,
      lspID: lspID,
    },
  };
};

/**
 * Helper function - updateVendorProfileLanguagePairRequest
 *
 * This function generates a request object for updating a vendor profile language on the provided values.
 *
 * @param {Object} values - The values to be used for the vendor profile language request.
 * @returns {Object} The request object for updating vendor profile language.
 */
export const updateVendorProfileLanguagePairRequest = (values = {}) => {
  const {
    id,
    sourceLanguage,
    targetLanguage,
    industry,
    jobType,
    organizationID,
    lspID,
    appName,
  } = values;

  return {
    variables: {
      request: {
        id,
        languageFromID: sourceLanguage?.value,
        languageToID: targetLanguage?.value,
        industryID: industry?.value,
        jobTypeID: jobType?.value,
        organizationID,
        lspID,
        appName,
      },
    },
  };
};

/**
 * Helper function - inviteVendorUserRequest
 *
 * This function generates a request object for inviting a vendor user based on the provided values.
 *
 * @param {Object} values - The values to be used for the vendor user invitation request.
 * @returns {Object} The request object for inviting a vendor user.
 */
export const inviteVendorUserRequest = (values = {}) => {
  const { email, firstName, lastName, role, vendorProfileID } = values;

  return {
    variables: {
      input: {
        email,
        firstName,
        lastName,
        roleID: role,
        vendorProfileID,
      },
    },
  };
};

/**
 * Helper function - registerUserRequest
 *
 * This function generates a request object for registering a user based on the provided values.
 *
 * @param {Object} values - The values to be used for the user registration request.
 * @returns {Object} The request object for registering a user.
 */
export const registerUserRequest = (values = {}, appType) => {
  const { email, password, firstName, lastName, code } = values;

  return {
    variables: {
      input: {
        firstName,
        lastName,
        username: email,
        password,
        phone: "",
        code,
        appType,
      },
    },
  };
};

/**
 * Helper function - paginatedVendorInvitationRequest
 *
 * This function generates a request object for preparing paginated vendor invitation based on the provided props.
 *
 * @param {Object} props - The props to be used for preparing the vendor invitation request.
 * @returns {Object} The request object for preparing the vendor invitations.
 */
export const paginatedVendorInvitationRequest = (props) => {
  const { pageIndex, pageSize, vendorProfileID, excludeAccepted } = props;

  return {
    variables: {
      input: {
        vendorProfileID,
        offset: pageIndex * pageSize,
        pageLimit: pageSize,
        excludeAccepted: excludeAccepted,
      },
    },
  };
};

export const vendorOnboardingStep1Request = (values) => {
  const {
    vendorProfileID,
    name,
    firstName,
    lastName,
    title,
    phoneNumber,
    country,
    address1,
    address2,
    city,
    postalCode,
    industries,
    state,
  } = values;

  return {
    vendorProfileID,
    basicInfo: {
      name,
      industries: industries?.map((industry) => industry?.value),
    },
    companyAdmin: {
      firstName,
      lastName,
      title,
      phoneNumber,
    },
    companyAddess: {
      country: country?.value,
      address1,
      address2,
      city,
      postalCode,
      state,
    },
  };
};

export const inviteClientUserRequest = (values = {}) => {
  const { email, firstName, lastName, role, clientProfileID } = values;

  return {
    variables: {
      input: {
        email,
        firstName,
        lastName,
        roleID: role,
        clientProfileID,
        appName: envs.appName,
      },
    },
  };
};

export const paginatedClientInvitationsRequest = (props) => {
  const {
    pageIndex,
    pageSize,
    clientProfileID,
    excludeAccepted = false,
  } = props;

  return {
    variables: {
      input: {
        clientProfileID,
        offset: pageIndex * pageSize,
        pageLimit: pageSize,
        excludeAccepted: excludeAccepted,
      },
    },
  };
};

export const clientOnboardingStep1Request = (values) => {
  const {
    clientProfileID,
    name,
    firstName,
    lastName,
    title,
    email,
    phoneNumber,
    industries,
  } = values;

  return {
    clientProfileID,
    basicInfo: {
      name,
      industries: industries?.map((industry) => industry?.value),
    },
    companyAdmin: {
      firstName,
      lastName,
      title,
      email,
      phoneNumber,
    },
  };
};

// creates inviteOrgUserRequest using values which is to be used in inviteOrgUser command
export const inviteOrgUserRequest = (values = {}) => {
  const { email, firstName, lastName, role, orgID } = values;

  return {
    variables: {
      input: {
        email,
        firstName,
        lastName,
        roleID: role,
        orgID,
      },
    },
  };
};

// creates paginatedOrgInvitationsRequest using values which is to be used in paginatedOrgInvitations query
export const paginatedOrgInvitationsRequest = (props) => {
  const { pageIndex, pageSize, orgID } = props;

  return {
    variables: {
      input: {
        orgID,
        offset: pageIndex * pageSize,
        pageLimit: pageSize,
      },
    },
  };
};

export const preparePaginatedOrgNotificationsRequest = (props) => {
  const { pageIndex, pageSize, organizationID } = props;

  return {
    variables: {
      input: {
        organizationID,
        pagination: {
          offset: pageIndex * pageSize,
          pageLimit: pageSize,
        },
      },
    },
  };
};

export const prepareClientProfileVRISettingsRequest = (
  values,
  clientProfileID,
  activeOrg,
) => {
  const lspRequest = {
    variables: {
      clientProfileInput: {
        organizationID: activeOrg,
        clientProfileID,
        isScreenShareFeatureEnabled: values?.isScreenShareFeatureEnabled,
        isVirtualBackgroundFeatureEnabled:
          values?.isVirtualBackgroundFeatureEnabled,
      },
    },
  };

  const buyerRequest = {
    variables: {
      clientProfileInput: {
        organizationID: activeOrg,
        clientProfileID,
        enableScreenShare: values?.enableScreenShare,
        enableVirtualBackground: values?.enableVirtualBackground,
        jobWordCountLimitEnabled: values?.jobWordCountLimitEnabled,
        automaticallyDeleteDocuments: values?.automaticallyDeleteDocuments,
        glossarySupport: values?.glossarySupport,
      },
    },
  };
  return isBuyerApp ? buyerRequest : lspRequest;
};

export const prepareOrganizationVRISettings = (activeOrgID, values) => {
  return {
    variables: {
      input: {
        id: activeOrgID,
        enableScreenShare: values?.enableScreenShare,
        enableVirtualBackground: values?.enableVirtualBackground,
      },
    },
  };
};

// preparing prepareExportProjectJobsRequest for Jobs
export const prepareExportProjectJobsRequest = (
  exportType,
  projectID,
  orgID,
  filters,
) => {
  return {
    variables: {
      organizationID: orgID,
      projectID: projectID,
      requestType: exportType,
      filtersReq: filters,
    },
  };
};

export const prepareFetchVendors = ({ organizationID }) => ({
  variables: {
    input: {
      organizationID,
      pagination: { offset: 0, pageLimit: 500 },
      filtersReq: {
        commonFilters: [{ key: "status", values: ["draft", "active"] }],
        customFilters: [],
      },
    },
  },
});

export function prepareOrgSettingsRequest(formValues, activeOrg, clientProfileID) {
  return {
    variables: {
      organizationID: activeOrg?.id,    
      customLimitText: formValues.requestWordLimitText,
      changedOrgSettings: {
        id: activeOrg?.id,
        recordViewModeID: formValues.viewMode?.value,
      },
      clientProfileInput: {
        organizationID: activeOrg?.id,
        clientProfileID,
        jobWordCountLimit: formValues.requestWordLimit,
      },
    },
  };
};
