import { Button } from "@aws-amplify/ui-react";
import { CogIcon } from "@heroicons/react/solid";

import { useModal } from "@lango/common/context";
import Flex from "../../tables/components/Flex";

export const CheckboxSettings = ({ _component, heading }) => {
  const { showModal } = useModal();

  return (
    <Flex justify="end">
        <Button
          onClick={() =>
            showModal(_component, {
              heading: heading,
              size: "2xl",
            })
          }
        >
          <CogIcon className="ml-auto  w-6 h-6" />
        </Button>
    </Flex>
  );
};
