import { useClientProfile, useVendorProfile } from "@lango/common/context";
import useUpdateVendorProfileServiceLines
  from "./updateVendorProfileServiceLines";
import useUpdateOrganizationServiceLines from "./updateOrganizationServiceLines";
import { toastError, toastSuccess } from "@lango/common/features";
import { ServiceLines } from "./ServiceLines";

export function VendorServiceLinesWrapper({canUpdateOrg}) {
  const { vendorProfile } = useVendorProfile();
  const vendorProfileID = vendorProfile?.id;

  return (
    <ServiceLinesWrapper
      vendorProfileID={vendorProfileID}
      canUpdateOrg={canUpdateOrg}
      serviceLinesType={"vendor"}
    />
  );
}

export function ClientServiceLinesWrapper({ canUpdateOrg }) {
  const { clientProfile } = useClientProfile();
  const clientOrgID = clientProfile?.organizationID;

  return (
    <ServiceLinesWrapper
      clientOrgID={clientOrgID}
      canUpdateOrg={canUpdateOrg}
      serviceLinesType={"client"}
    />
  );
}

function ServiceLinesWrapper({ vendorProfileID, clientOrgID, canUpdateOrg, serviceLinesType }) {

  const { updateVendorProfileServiceLines } =
    useUpdateVendorProfileServiceLines();

  const { updateOrganizationServiceLines } = useUpdateOrganizationServiceLines();

  async function handleUpdateServiceLines(values) {
    const jobTypeIDs = Object.keys(values?.selectedJobTypes).filter(
      (key) => values?.selectedJobTypes[key],
    );
    if (!jobTypeIDs.length) {
      toastError("You must select at least one service line.");
      return;
    }
    if (!canUpdateOrg) {
      toastError("You do not have permission to update service lines.");
      return;
    }
    try {
      switch(serviceLinesType) {
        case "vendor":
          await updateVendorProfileServiceLines({
            vendorProfileID,
            jobTypeIDs,
          });
          break;
        case "client":
          await updateOrganizationServiceLines(clientOrgID, jobTypeIDs);
          break;
        default:
          console.error("Invalid service lines type");
      }

      toastSuccess("Service lines updated");
    } catch (e) {
      console.error(e);
      toastError("Failed to update service lines");
    }
  }

  return (
    <ServiceLines
      vendorProfileID={vendorProfileID}
      organizationID={clientOrgID}
      canUpdateOrg={canUpdateOrg}
      onSubmit={handleUpdateServiceLines}
    />
  );
}

export default ServiceLinesWrapper;
