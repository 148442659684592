import { useContext, useEffect, useRef } from "react";

import { PusherContext } from "./pusherProvider";

// Custom hook to subscribe to a Pusher channel event and handle the event with a callback.
export const useChannelEvent = (channelName, channelEvent, callbackHandler) => {
  const pusher = useContext(PusherContext);
  const eventCallbackRef = useRef(callbackHandler);

  useEffect(() => {
    eventCallbackRef.current = callbackHandler;
  }, [callbackHandler]);

  useEffect(() => {
    if (!channelName || !channelEvent ) return;
    const channel = pusher.subscribe(channelName);

    const handleEvent = (data) => {
      if (eventCallbackRef.current) {
        eventCallbackRef.current(data);
      }
    };

    channel.bind(channelEvent, handleEvent);
    console.log("subscribed to channel: " + channelName + " event: " + channelEvent);

    return () => {
      channel.unbind(channelEvent, handleEvent);
      pusher.unsubscribe(channelName);
    };
  }, [channelName, channelEvent, pusher]);
};
