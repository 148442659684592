import { Formik } from "formik";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { signIn, signOut } from "aws-amplify/auth";

import { loginValidationSchema } from "@lango/common/validations";
import { toastError } from "@lango/common/features";
import { vendorInvitationInitialValues } from "@lango/common/initialValues";
import { useAcceptOrgInviteCtx, useModal } from "@lango/common/context";

import { InviteLoginForm } from "../loginInvitation/fields";
import { ResendCode, resendCodeModalProps } from "../../auth/components/ResendCode";

function LoginOrgInvitation() {
  const { showModal } = useModal();
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  const { verifyResponse, handleChangeInviteStep } = useAcceptOrgInviteCtx();

  async function handleLogin(
    /** @type {any} */ values,
    /** @type {import("formik").FormikHelpers} */ helpers,
  ) {
    try {
      const { username, password } = values;
      helpers?.setFieldValue("disableFields", true);
      authStatus === "authenticated" && (await signOut());
      // todo: use common hook for this
      const { nextStep } = await signIn({ username, password });
      if (nextStep.signInStep === "CONFIRM_SIGN_UP") {
        showModal(ResendCode, { ...resendCodeModalProps, username });
        helpers?.setFieldValue("disableFields", false);
        return;
      }
      handleChangeInviteStep("link");
    } catch (error) {
      toastError(error?.message || error);
      console.error(error);
    } finally {
      helpers.setFieldValue("disableFields", false);
    }
  }

  return (
    <Formik
      validationSchema={loginValidationSchema}
      initialValues={vendorInvitationInitialValues(verifyResponse)}
      onSubmit={handleLogin}
    >
      <InviteLoginForm />
    </Formik>
  );
}
export default LoginOrgInvitation;
