/**
 * This file contains the implementation of Step1 component for the onboarding feature.
 * The Step1 component is responsible for rendering the appropriate form based on the vendor type (Individual or Agency).
 * It uses Formik for form handling and validation.
 * The AgencyStep1 component is rendered when the vendor type is Agency, and the IndividualStep1 component is rendered when the vendor type is Individual.
 * Each step form includes a BasePageHeading component, form fields, and an action button.
 */

import { useReactiveVar } from "@apollo/client";
import { Form, Formik } from "formik";

import {
  userFirstNameVar,
  userGenderVar,
  userLastNameVar,
  usernameVar,
} from "@lango/common/apollo";
import { useAcceptVendorInviteCtx } from "@lango/common/context";
import BasePageHeading from "@lango/common/features/header/components/BasePageHeading";
import {
  useFetchVendorOnboardingStatus,
  useFindVendorOnboardStep1,
  useLinguistOnboardingStep1,
  useVendorOnboardSteps,
} from "@lango/common/hooks";
import {
  individualOnboardStep2InitialValues,
  vendorOnboardStep1InitialValues,
} from "@lango/common/initialValues";
import {
  validateVendorIndividualStep1,
  validateVendorOnboardingStep1,
} from "@lango/common/validations";
import { Action } from "../common/fields";
import InfoBars from "../progressBar";
import { AgencyFields, IndividualFields } from "./fields";

/**
 * Step1 component that renders the appropriate form based on the vendor type.
 * If the vendor type is Individual, it renders the IndividualStep1 component.
 * If the vendor type is Agency, it renders the AgencyStep1 component.
 */
export default function Step1() {
  const { isIndividual } = useAcceptVendorInviteCtx();
  return isIndividual() ? <IndividualStep1 /> : <AgencyStep1 />;
}

/**
 * AgencyStep1 component that renders the form for agency onboarding.
 * It uses Formik for form handling and validation.
 * Includes a BasePageHeading component, form fields, and an action button.
 */
function AgencyStep1() {
  const { handleOnboardingStep1 } = useVendorOnboardSteps();
  const { onboardingData } = useAcceptVendorInviteCtx();
  const user = usePrepareUser();

  const { status } = useFetchVendorOnboardingStatus();
  const { vendorProfile: data } = useFindVendorOnboardStep1();

  const industries = Array.isArray(data?.industries) ? data.industries : [];

  const initialValues = vendorOnboardStep1InitialValues({
    name: onboardingData?.profileName,
    city: data?.city,
    country: data?.country,
    address1: data?.address1,
    address2: data?.address2 || "",
    state: data?.state,
    industries: industries,
    postalCode: data?.postalCode,
    title: data?.title,
    phoneNumber: data?.phone || "",
    ...user,
  });

  const { colorOne, colorTwo } = firstStepColours(status?.currentStep);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleOnboardingStep1}
      validationSchema={validateVendorOnboardingStep1}
      enableReinitialize
    >
      <Form>
        <BasePageHeading
          heading="Agency onboarding"
          description="Step 1 of 3"
        />
        <InfoBars
          status={status?.currentStep}
          step2={"Billing Contact"}
          step3={"Users"}
          colorOne={colorOne}
          colorTwo={colorTwo}
        />
        <AgencyFields />
        <Action />
      </Form>
    </Formik>
  );
}

/**
 * IndividualStep1 component that renders the form for individual linguist onboarding.
 * It uses Formik for form handling and validation.
 * Includes a BasePageHeading component, form fields, and an action button.
 */
function IndividualStep1() {
  const user = usePrepareUser();
  const { handleIndividualOnboardingStep1 } = useVendorOnboardSteps();
  const { vendorProfile: data } = useLinguistOnboardingStep1();

  const initialValues = individualOnboardStep2InitialValues({
    address1: data?.address1,
    address2: data?.address2 || "",
    country: data?.country,
    postalCode: data?.postalCode,
    city: data?.city,
    phone: data?.phone,
    state: data?.state,
    ...user,
  });

  const { status } = useFetchVendorOnboardingStatus();
  const { colorOne, colorTwo } = firstStepColours(status?.currentStep);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleIndividualOnboardingStep1}
      validationSchema={validateVendorIndividualStep1}
      enableReinitialize
    >
      <Form>
        <BasePageHeading
          heading="Linguist onboarding"
          description="Step 1 of 3"
        />
        <InfoBars
          status={status?.currentStep}
          step2={"Service Lines"}
          step3={"Language Pairs"}
          colorOne={colorOne}
          colorTwo={colorTwo}
        />
        <IndividualFields />
        <Action />
      </Form>
    </Formik>
  );
}
/**
 * Custom hook that prepares the user data for onboarding.
 * Retrieves the user's first name, last name, and email from reactive variables.
 * @returns The prepared user data.
 */
export function usePrepareUser() {
  const firstName = useReactiveVar(userFirstNameVar);
  const lastName = useReactiveVar(userLastNameVar);
  const email = useReactiveVar(usernameVar);
  const gender = useReactiveVar(userGenderVar);

  return {
    firstName,
    lastName,
    email,
    gender,
  };
}

function firstStepColours(currentStep) {
  if (currentStep === "step1" || currentStep === "started") {
    return { colorOne: "white", colorTwo: "white" };
  } else if (currentStep === "step2") {
    return { colorOne: "white", colorTwo: "white" };
  }
  return { colorOne: "white", colorTwo: "white" };
}
