import { useQuery, useReactiveVar } from "@apollo/client";

import {
  activeLSPVar,
  activeOrgVar,
  invitedLSPVar,
  updateActiveLSP,
} from "@lango/common/apollo/localState";
import envs from "@lango/common/env";
import { ORGANIZATION_LSPS } from "@lango/common/queries";

export const useGetOrgLSPs = () => {
  const activeOrg = useReactiveVar(activeOrgVar);
  const { data, error, loading } = useQuery(ORGANIZATION_LSPS, {
    variables: {
      organizationID: activeOrg?.id,
      sourceAppName: envs.appName,
    },
    skip: !activeOrg?.id,
    onCompleted: (data) => {
      /**
       * Updates the active LSP based on the fetched organization LSPs.
       * Order of priority for setting the active LSP:
       *   1. Newly accepted invitation LSP
       *   2. Previously used LSP for the organization
       *   3. The LSP in use from the last organization
       *   4. The first fetched LSP
       */
      const prevLSP = activeLSPVar();
      const invitedLSPId = invitedLSPVar();
      const newLSPs = data?.findOrganizationLSPs || [];
      const lastActiveLSP = data?.getUserLastLoginUnderOrg?.lastAccessedLsp;

      const findLSPById = (id) => newLSPs?.find((lsp) => lsp?.id === id);

      const updatedLSP = findLSPById(prevLSP?.id);
      const invitedLSP = findLSPById(invitedLSPId);
      const activeLSP = findLSPById(lastActiveLSP?.id);

      const selectedLSP =
        invitedLSP || activeLSP || updatedLSP || newLSPs[0] || {};

      updateActiveLSP(selectedLSP);
      selectedLSP?.id === invitedLSPId && invitedLSPVar(null);
    },
    onError: (error) => {
      console.error(error);
    },
    fetchPolicy: "cache-and-network",
  });

  return { loading, error, orgLSPs: data?.findOrganizationLSPs || [] };
};
