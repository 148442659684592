import { Formik } from "formik";
import ClientMTettingsFields from "./clientMTSettingsFields";
import {
  useFetchClientProfileSettings,
  useUpdateClientProfileVRISettings,
} from "@lango/common/hooks";
import { WithLoader } from "@lango/common/components";

const ClientMTSettingsForm = () => {
  const { handleUpdateClientVRISettings } =
    useUpdateClientProfileVRISettings();
  const {
    settings,
    error,
    loading,
    isAutomaticallyDeleteDocumentsFeatureEnabled,
    isGlossarySupportFeatureEnabled,
    isJobWordCountLimitFeatureEnabled,
  } = useFetchClientProfileSettings();
  return (
    <WithLoader error={error} loading={loading}>
      <Formik initialValues={settings} onSubmit={handleUpdateClientVRISettings}>
        <ClientMTettingsFields
          isAutoDeleteDocuments={isAutomaticallyDeleteDocumentsFeatureEnabled}
          isGlossarySupport={isGlossarySupportFeatureEnabled}
          isJobWordCountLimit={isJobWordCountLimitFeatureEnabled}
        />
      </Formik>
    </WithLoader>
  );
};

export default ClientMTSettingsForm;
