import BasePageHeading from "@lango/common/features/header/components/BasePageHeading";
import { Action } from "@lango/common/features/onboarding";
import { useClientOnboardSteps } from "@lango/common/hooks";
import { clientContactInitialValues } from "@lango/common/initialValues";
import { validateClientOnboardingStep2 } from "@lango/common/validations";
import { Form, Formik } from "formik";
import Fields from "./fields";

export default function Step2() {
  const { handleOnboardingStep2 } = useClientOnboardSteps();
  return (
    <Formik
      initialValues={clientContactInitialValues}
      onSubmit={handleOnboardingStep2}
      validationSchema={validateClientOnboardingStep2}
    >
      <Form>
        <BasePageHeading
          heading="Client onboarding"
          description="Step 2 of 4"
        />
        <Fields />
        <Action />
      </Form>
    </Formik>
  );
}
