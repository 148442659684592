import { countries, getCountryDataList } from "countries-list";

export const COUNTRY_OPTIONS = getCountryDataList()
  .map((country) => ({
    label: country.name,
    value: country.iso2,
  }))
  .sort((a, b) => {
    // ensure US is first
    if (a.value === "US") {
      return -1;
    }
    if (b.value === "US") {
      return 1;
    }
    return a.label.localeCompare(b.label);
  });

/**
 * Gets the option for a Select component based on the country's iso2 code.
 * If the country is not found, default to an option with a label and value equal to the input
 * @param {string} code - The two letter iso code for the country
 * @returns {Object|null} The option object for the Select component, or null if the input is falsy (null or blank)
 */
export const getCountryOption = (code) => {
  if (!code) {
    return null;
  }

  const countryData = countries[code];
  return { label: countryData?.name || code, value: code };
};
