import { Formik } from "formik";
import ClientVRISettingFields from "./clientVRISettingFields";
import {
  useFetchClientProfileSettings,
  useUpdateClientProfileVRISettings,
} from "@lango/common/hooks";
import { WithLoader } from "@lango/common/components";

const ClientVRISettingsForm = () => {
  const { handleUpdateClientVRISettings } = useUpdateClientProfileVRISettings();
  const {
    settings,
    error,
    loading,
    isScreenShareFeatureEnabled,
    isVirtualBackgroundFeatureEnabled,
  } = useFetchClientProfileSettings();

  return (
    <WithLoader error={error} loading={loading}>
      <Formik initialValues={settings} onSubmit={handleUpdateClientVRISettings}>
        <ClientVRISettingFields
          isEnableVirtualBackground={isVirtualBackgroundFeatureEnabled}
          isEnableScreenShare={isScreenShareFeatureEnabled}
        />
      </Formik>
    </WithLoader>
  );
};

export default ClientVRISettingsForm;
