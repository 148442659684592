import { isValidPhoneNumber } from "react-phone-number-input";
import * as yup from "yup";

import {
  VENDOR_AGENCY_TYPE,
  VENDOR_INDIVIDUAL_TYPE,
} from "@lango/common/constants";
import {
  maxPhoneNumberLength,
  validateAddress,
  validateAddressDetails,
  validateCountry,
  validatePhoneNumber,
  validateState,
} from "./common";

// messages
const errSameLanguages = "Source language shouldn't match the target language";
const targetLanguageRequired = "At least one target language is required";
const jobTypeRequired = "At least one service line is required";

function validateSameLanguages(targetLanguage, ctx) {
  if (!targetLanguage) return true;
  if (targetLanguage?.value === ctx?.parent?.sourceLanguage?.value) {
    return this.createError({
      path: "sourceLanguage",
      message: errSameLanguages,
    });
  }
  return true;
}

const validateEmail = yup.object({
  email: yup.string().email().required(),
});

const sourceLanguage = yup
  .object()
  .shape({ label: yup.string(), value: yup.number() })
  .nullable()
  .required("Source language is required");

const targetLanguage = yup
  .object()
  .shape({ label: yup.string(), value: yup.number() })
  .nullable()
  .required("Target language is required")
  .test("is-unique", errSameLanguages, validateSameLanguages);

export const validateCreateOnDemand = yup.object({
  sourceLanguage,
  targetLanguage,
  gender: yup
    .object()
    .shape({ label: yup.string(), value: yup.number() })
    .required("Gender preference is required")
    .nullable(),
});

export const validateHTRequest = yup.object({
  sourceLanguage: yup
    .object()
    .shape({ label: yup.string(), value: yup.number() })
    .nullable(),
  targetLanguage,
  estimatedPrice: yup
    .number()
    .min(0, "Estimated cost cannot be negative")
    .nullable(),
});

export const validateHTJobTask = yup.object({
  rate: yup.number().min(0, "Rate cannot be negative").nullable(),
});

export const validateInviteUsers = (values) => {
  const errors = {};

  const emailErrors = [];
  const emailSet = new Set();

  values?.emails?.forEach((emailObj, index) => {
    if (!emailObj.email) {
      emailErrors[index] = { email: "Email is required" };
    } else if (!validateEmail.isValidSync({ email: emailObj.email })) {
      emailErrors[index] = { email: "Invalid email address" };
    } else if (emailSet.has(emailObj.email)) {
      emailErrors[index] = { email: "Duplicate email address" };
    } else {
      emailSet.add(emailObj.email);
    }
  });

  if (emailErrors.length > 0) {
    errors.emails = emailErrors;
  }

  if (!values.role) {
    errors.role = "Role is required";
  }

  return errors;
};

export const loginValidationSchema = yup.object().shape({
  username: yup.string().email().required("Email is required"),
  password: yup.string().when("localLogin", {
    is: true,
    then: yup.string().required("Password is required"),
    otherwise: yup.string().notRequired(),
  }),
});

export const forgotPasswordSchema = yup.object().shape({
  username: yup.string().email().required("Email is required"),
});

const validatePassword = yup
  .string()
  .min(8, "Password must be at least 8 characters long")
  .matches(/^(?!\s)(?!.*\s$)/, "Password cannot begin or end with whitespace")
  .matches(/[a-z]/, "Password must contain at least one lowercase letter")
  .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
  .matches(/[0-9]/, "Password must contain at least one number")
  .matches(
    /[!@#$%^&*(),.?":{}|<>]/,
    "Password must contain at least one symbol",
  )
  .required("New password is required");

export const confirmPasswordSchema = yup.object({
  username: yup.string().email().required("Email is required"),
  code: yup.string().required("Code is required"),
  newPassword: validatePassword,
  repeatNewPassword: yup
    .string()
    .oneOf([yup.ref("newPassword"), null], "Passwords must match")
    .required("Repeat password is required"),
});

export const createClient = yup.object().shape({
  name: yup.string().min(5).required("Client name is required"),
});

// Base schema for common validations
const commonVendorSchema = yup.object().shape({
  vendorType: yup.number().required("Vendor type is required").nullable(),
  name: yup.string().when("vendorType", {
    is: (val) => !val || val === VENDOR_AGENCY_TYPE, // check if vendor type is agency
    then: yup.string().required("Vendor Name is required"),
    otherwise: yup.string().notRequired(),
  }),
  firstName: yup.string().when("vendorType", {
    is: (val) => !val || val === VENDOR_INDIVIDUAL_TYPE, // check if vendor type is individual
    then: yup.string().required("Vendor First Name is required"),
    otherwise: yup.string().notRequired(),
  }),
  lastName: yup.string().when("vendorType", {
    is: (val) => !val || val === VENDOR_INDIVIDUAL_TYPE, // check if vendor type is individual
    then: yup.string().required("Vendor Last Name is required"),
    otherwise: yup.string().notRequired(),
  }),
});

// Update Vendor Schema
export const updateVendor = commonVendorSchema.shape({
  status: yup.string().max(255, "Must be 255 characters or less"),
  billingNotes: yup.string().max(255, "Must be 255 characters or less"),
  generalNotes: yup.string().max(255, "Must be 255 characters or less"),
  billingTerms: yup.string().max(255, "Must be 255 characters or less"),
});

export const validateInviteClientVendor = yup.object().shape({
  email: yup.string().email().required("Email is required"),
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  role: yup.number().required("Role is required"),
});

/**
 * validateAddJobRouting Schema
 *
 * This schema defines validation rules for adding/updating a job routing.
 * It includes validation for source language, target languages, and job type.
 *
 * @param {Function} validateLanguages - The validation function for checking source and target languages.
 */
export const validateJobRouting = yup.object().shape({
  sourceLanguage: yup
    .object()
    .shape({ label: yup.string(), value: yup.number() })
    .required("Source language is required")
    .nullable(),
  targetLanguage: yup
    .array()
    .of(yup.object().shape({ label: yup.string(), value: yup.number() }))
    .required(targetLanguageRequired)
    .min(1, targetLanguageRequired)
    .nullable()
    .test(
      "is-unique",
      "Source language shouldn't be included in the target languages",
      validateLanguages,
    ),
  jobType: yup
    .object()
    .shape({ label: yup.string(), value: yup.number() })
    .required("Job Type is required")
    .nullable(),
});

/**
 * validateLanguages Function
 *
 * This function validates the source language is not included in the target languages array.
 *
 * @param {Object} ctx - The context object containing source and target languages.
 * @returns {boolean} - True if validation passes, false otherwise.
 */
function validateLanguages(ctx) {
  const { sourceLanguage, targetLanguage } = this.parent;
  if (sourceLanguage && targetLanguage) {
    const sourceValue = sourceLanguage.value;
    const targetValues = targetLanguage.map((lang) => lang.value);
    return !targetValues.includes(sourceValue);
  }
  return true;
}

/**
 * validateCreateRoutingLevel Schema
 *
 * This schema defines validation rules for creating a routing level.
 */
export const validateCreateRoutingLevel = yup.object({
  levelName: yup.string().required("Level name is required"),
});

/**
 * validateLabel: validates creatable job labels
 */
export function validateLabel(
  /** @type {String} */ inputValue,
  _,
  /** @type {ReadonlyArray} */ options,
) {
  return (
    inputValue &&
    !options?.find(
      (option) =>
        option?.label?.toLowerCase() === inputValue?.trim()?.toLowerCase(),
    ) &&
    inputValue?.trim() === inputValue
  );
}

export const validateVendorProfileLanguage = yup.object({
  sourceLanguage,
  targetLanguage,
  jobType: yup
    .object()
    .shape({ label: yup.string(), value: yup.number() })
    .required("Job Type is required")
    .nullable(),
});

/**
 * validateRegisterUserForm Schema
 *
 * This schema defines validation rules for registering a new user.
 */
export const validateRegisterUserForm = yup.object({
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  email: yup.string().email().required("Email is required"),
  password: validatePassword,
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Confirm password is required"),
});

/**
 * validateVendorContact Schema
 *
 * This schema defines validation rules for adding vendor contacts.
 */
export const validateVendorContact = yup.object({
  firstName: yup
    .string()
    .required("First name is required")
    .max(50, "First name can be a maximum of 50 characters"),
  lastName: yup
    .string()
    .required("Last name is required")
    .max(50, "Last name can be a maximum of 50 characters"),
  email: yup
    .string()
    .email()
    .required("Email is required")
    .max(255, "Email can be a maximum of 255 characters"),
  phone: validatePhoneNumber,
  faxNumber: yup
    .string()
    .optional()
    .max(50, "Fax number can be a maximum of 50 characters"),
  country: validateCountry,
  address1: validateAddress,
  address2: validateAddressDetails,
  city: yup
    .string()
    .required("City is required")
    .max(25, "City can be a maximum of 25 characters"),
  state: validateState,
  postalCode: yup
    .string()
    .required("Postal code is required")
    .max(10, "Postal code can be a maximum of 10 characters"),
});

export const validateVendorOnboardingStep1 = yup.object({
  name: yup
    .string()
    .required("Vendor profile name is required")
    .max(50, "Vendor profile name can be a maximum of 50 characters"),
  firstName: yup
    .string()
    .required("First name is required")
    .max(50, "First name can be a maximum of 50 characters"),
  lastName: yup
    .string()
    .required("Last name is required")
    .max(50, "Last name can be a maximum of 50 characters"),
  title: yup
    .string()
    .nullable()
    .notRequired()
    .max(50, "Title can be a maximum of 50 characters"),
  email: yup
    .string()
    .email()
    .required("Email is required")
    .max(255, "Email can be a maximum of 255 characters"),
  phoneNumber: yup
    .string()
    .nullable()
    .notRequired()
    .max(maxPhoneNumberLength, "Phone can be a maximum of 15 characters")
    .test("isValidPhoneNumber", "Phone number is not valid", (value) =>
      value ? isValidPhoneNumber(value) : true,
    ),
  country: validateCountry,
  address1: validateAddress,
  address2: validateAddressDetails,
  city: yup
    .string()
    .required("City is required")
    .max(25, "City can be a maximum of 25 characters"),
  state: validateState,
  postalCode: yup
    .string()
    .required("Postal code is required")
    .max(10, "Postal code can be a maximum of 10 characters"),
});

export const validateClientOnboardingStep1 = yup.object({
  name: yup
    .string()
    .required("Client profile name is required")
    .max(50, "Client profile name can be a maximum of 50 characters"),
  firstName: yup
    .string()
    .required("First name is required")
    .max(50, "First name can be a maximum of 50 characters"),
  lastName: yup
    .string()
    .required("Last name is required")
    .max(50, "Last name can be a maximum of 50 characters"),
  title: yup
    .string()
    .required("Title is required")
    .max(50, "Title can be a maximum of 50 characters"),
  email: yup
    .string()
    .email()
    .required("Email is required")
    .max(255, "Email can be a maximum of 255 characters"),
  phoneNumber: validatePhoneNumber,
});

export const validateClientOnboardingStep2 = yup.object({
  firstName: yup
    .string()
    .required("First name is required")
    .max(50, "First name can be a maximum of 50 characters"),
  lastName: yup
    .string()
    .required("Last name is required")
    .max(50, "Last name can be a maximum of 50 characters"),
  email: yup
    .string()
    .email()
    .required("Email is required")
    .max(255, "Email can be a maximum of 255 characters"),
  phone: validatePhoneNumber,
  faxNumber: yup
    .string()
    .optional()
    .max(50, "Fax number can be a maximum of 50 characters"),
  website: yup
    .string()
    .url()
    .optional()
    .max(255, "Website can be a maximum of 255 characters"),
  country: validateCountry,
  address1: validateAddress,
  address2: validateAddressDetails,
  city: yup
    .string()
    .required("City is required")
    .max(25, "City can be a maximum of 25 characters"),
  postalCode: yup
    .string()
    .required("Postal code is required")
    .max(10, "Postal code can be a maximum of 10 characters"),
});

export const validateClientOnboardingStep3 = yup.object({
  serviceLocations: yup.array().of(
    yup.object({
      locationName: yup
        .string()
        .required("Location name is required")
        .max(50, "Location name can be a maximum of 50 characters"),
      country: validateCountry,
      address1: validateAddress,
      address2: validateAddressDetails,
      city: yup
        .string()
        .required("City is required")
        .max(25, "City can be a maximum of 25 characters"),
      postalCode: yup
        .string()
        .required("Postal code is required")
        .max(10, "Postal code can be a maximum of 10 characters"),
      phone: validatePhoneNumber,
    }),
  ),
});

// todo: make common validations for vendor and client
export const validateVendorIndividualStep1 = yup.object({
  firstName: yup
    .string()
    .nullable()
    .required("First name is required")
    .max(50, "First name can be a maximum of 50 characters"),
  lastName: yup
    .string()
    .nullable()
    .required("Last name is required")
    .max(50, "Last name can be a maximum of 50 characters"),
  email: yup
    .string()
    .nullable()
    .email()
    .required("Email is required")
    .max(255, "Email can be a maximum of 255 characters"),
  phone: yup
    .string()
    .nullable()
    .notRequired()
    .max(maxPhoneNumberLength, "Phone can be a maximum of 15 characters")
    .test("isValidPhoneNumber", "Phone number is not valid", (value) =>
      value ? isValidPhoneNumber(value) : true,
    ),
  country: validateCountry,
  address1: validateAddress,
  address2: validateAddressDetails,
  city: yup
    .string()
    .nullable()
    .required("City is required")
    .max(25, "City can be a maximum of 25 characters"),
  state: validateState,
  postalCode: yup
    .string()
    .nullable()
    .required("Postal code is required")
    .max(10, "Postal code can be a maximum of 10 characters"),
});

export * from "./contact";
export * from "./rate-sheet";
export * from "./vendor";
