import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { isPMApp, isVendorApp } from "@lango/common/env";
import { toastError } from "@lango/common/features/alerts/functions/toast";
import { ORGANIZATION_NOTIFICATION } from "@lango/common/queries";

export const useReadOrgNotification = () => {
  const navigate = useNavigate();
  const [readNotification] = useMutation(ORGANIZATION_NOTIFICATION.READ);

  const handleReadNotification = useCallback(
    async (notificationID, orgID) => {
      try {
        await readNotification({ variables: { notificationID } });
        const to =
          isPMApp && orgID
            ? "/vendors/qualification-review/" + orgID
            : isVendorApp
              ? "/settings/vendor-language-pairs"
              : "/";
        navigate(to);
      } catch (error) {
        toastError("There was an error reading notifications");
        console.error(error);
      }
    },
    [readNotification],
  );

  return { handleReadNotification };
};
