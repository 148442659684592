import { Divider } from "@aws-amplify/ui-react";
import { Box } from "@lango/common/components";
import { FormControl } from "@lango/common/features";

import {
  FieldContainer,
  SelectIndustry,
  renderFieldGroup,
} from "../../onboarding/step1/fields";

const company_admin_fields = [
  {
    control: "input",
    name: "firstName",
    label: "First name",
    placeholder: "Enter first name",
  },
  {
    control: "input",
    name: "lastName",
    label: "Last name",
    placeholder: "Enter last name",
  },
  {
    control: "input",
    name: "title",
    label: "Job title",
    placeholder: "Enter job title",
  },
  {
    control: "input",
    type: "email",
    name: "email",
    label: "Email",
    placeholder: "Enter email",
  },
  {
    control: "phone",
    type: "tel",
    name: "phoneNumber",
    label: "Phone number",
    placeholder: "Enter phone number",
  },
];

function Fields() {
  return (
    <Box extraClasses="bg-white rounded-xl shadow-md my-8">
      <FieldContainer heading="Basic Info">
        <FormControl
          control="input"
          name="name"
          label="Client profile name"
          placeholder="Enter client profile name"
        />
        <div></div> {/* logo */}
        <SelectIndustry />
      </FieldContainer>
      <Divider />
      {renderFieldGroup("Company Admin", company_admin_fields)}
    </Box>
  );
}

export default Fields;
